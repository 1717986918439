<template>
  <OnboardingStep
    :title="$t('onboarding.bouncebackIntroView.title')"
    :next-text="$t('onboarding.bouncebackIntroView.nextText')"
    v-bind="$props"
  >
    <I18nArray tag="p" path="onboarding.bouncebackIntroView.paragraphs" />
    <RaiLink :to="{ name: 'onboarding.bounceback.setup' }">
      {{ $t("onboarding.bouncebackIntroView.createCampaignLink") }}
    </RaiLink>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import I18nArray from "@/components/I18nArray";
import { RaiLink } from "@/core-ui";

export default {
  name: "BouncebackIntroView",
  components: { I18nArray, RaiLink },
  extends: BaseStepView,
};
</script>
